import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View, RefreshControl, ScrollView, useWindowDimensions, Text} from 'react-native';

import StoreContext from '../utils/store-context';
import TimeDisplay from './time-display';
import Button from '../components/button';
import getColors from '../styles/colors';
import getUnits from '../utils/screen-units';

let count = 0 ;

export default function Stops({route, navigation}) {
  const store = useContext(StoreContext);
  const {vh, vw} = getUnits(useWindowDimensions());
  const colors = getColors();
  const [stops, setStops] = useState(null);
  const { trip } = route.params;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.background,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    message: {
      flex: -1,
      backgroundColor: colors.background,
      color: colors.hint,
      padding: 20 * vw,
      fontSize: 30,
    },
    cards: {
      flex: -1,
      width: '100%',
      alignItems: 'center',
    },
    buttons: {
      flexGrow: 0,
      flexShrink: 0,
      flexDirection: 'row',
      width: '100%',
      padding: 3 * vw,
      justifyContent: 'space-evenly',
    },
    card: {
      backgroundColor: colors.cardBackground,
      borderRadius: 1 * vw,
      width: 100 * vw,
      height: 12 * vw,
      paddingLeft: 1 * vw,
      paddingRight: 1 * vw,
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 0.5 * vw,
      flexDirection: 'row',
    },
    stopPassed: {
      color: colors.altText,
      fontSize: 5 * vw,
      flex: 1,
      marginLeft: 1 * vw,
      marginRight: 1 * vw,
    },
    stop: {
      color: colors.text,
      fontSize: 5 * vw,
      flex: 1,
      marginLeft: 1 * vw,
      marginRight: 1 * vw,
    },
    time: {
      color: colors.text,
      fontSize: 6 * vw,
    },
  });

  const getSeconds = (time) => ((new Date(time)).getTime() - store.tick.now) / 1000;

  const cards = stops?.stopovers?.map((stopover, index) => {
    const seconds = getSeconds(stopover.departure || stopover.arrival);

    return (
    <View key={index} style={styles.card}>
      <Text style={seconds>0 ? styles.stop : styles.stopPassed}>{stopover?.stop?.name}</Text>
      <TimeDisplay style={styles.time} time={stopover.departure || stopover.arrival} seconds={seconds}/>
    </View>
    );
  });

  const onBack = async () => {
    navigation.goBack();
  }

  const onRefresh = () => {
    updateStops();
  }

  const updateStops = async (showMessages = true) => {
    console.log('updateStops');
    if(showMessages){
      store.actions.setAppStatus({ status: 'loading', message: 'Finding Stops...'});
      setStops(null);
    }

    let data = null;

    try {
      data = await store.vbb.trip(trip.tripId, trip.line.name, {})
    } catch(e) {
      if(showMessages){
        store.actions.setAppStatus({
          status: 'error',
          message: 'Error getting stops'
        });
       }
      console.log('Stops Error:', e);
    }

    store.actions.setAppStatus({ status: 'done', message: ''});
    setStops(data.trip);
  }

  useEffect(() => {
    if (stops) {
      if (store.tick.minuteChange) {
        setStops(stops);
      } else {
        updateStops(false);
      }
    }
  }, [store.tick]);

  useEffect(() => {
    updateStops();
  }, []);

  return (
    <View style={styles.container}>
      {store.appStatus.status == 'start' &&
        <Text style={styles.message}>{store.appStatus.message ?? ''}</Text>
      }
      {store.appStatus.status == 'loading' &&
        <Text style={styles.message}>{store.appStatus.message ?? 'Loading...'}</Text>
      }
      {store.appStatus.status == 'error' &&
        <Text style={styles.message}>{store.appStatus.message ?? 'Error'}</Text>
      }
      {store.appStatus.status == 'done' &&
        <View style={styles.cards}>
          <ScrollView
            horizontal={false}
            snapToInterval={6 * vw}
            refreshControl={
              <RefreshControl
                refreshing={store.appStatus.status == 'loading'}
                onRefresh={onRefresh}
              />
            }
            >{cards?.length ? cards : [ <Text style={styles.message} key="0">No trips</Text> ]}</ScrollView>
        </View>
      }
      <View style={styles.buttons}>
        <Button title="Back" onPress={onBack}/>
      </View>
      <StatusBar style="auto"/>
    </View>
  );
}