import React from 'react';
import { StyleSheet, Text, Pressable, useWindowDimensions } from 'react-native';

import getColors from '../styles/colors';
import getUnits from '../utils/screen-units';

export default function StopCard(props) {
  const stop = props.stop;
  const onPress = props.onPress;
  const {vh, vw} = getUnits(useWindowDimensions());
  const colors = getColors();

  const text = {
    fontSize: 3 * vw,
    color: colors.altText,
  }

  const styles = StyleSheet.create({
    card: {
      backgroundColor: colors.cardBackground,
      // elevation: 10,
      // shadowColor: '#000',
      // shadowOffset: { width: 0, height: 2 },
      // shadowOpacity: 0.2,
      borderRadius: 1 * vw,
      width: 96 * vw,
      height: 12 * vw,
      marginLeft: 2 * vw,
      marginRight: 2 * vw,
      paddingLeft: 1 * vw,
      paddingRight: 1 * vw,
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 0.5 * vw,
      flexDirection: 'row',
    },
    name: {
      ...text,
      fontSize: 5 * vw,
      fontWeight: 'bold',
    },
  });

  return (
    <Pressable style={styles.card} onPress={onPress}>
      <Text numberOfLines={1} style={styles.name}>{stop.name}</Text>
    </Pressable>
  );
}