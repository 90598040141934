import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View, RefreshControl, ScrollView, useWindowDimensions, Text, Platform} from 'react-native';
import { RefreshControl as RefreshControlWeb } from 'react-native-web-refresh-control'

import StoreContext from '../utils/store-context';
import DepartureCard from '../components/departure-card';
import Button from '../components/button';
import getColors from '../styles/colors';
import getUnits from '../utils/screen-units';

let count = 0 ;

export default function Board({navigation}) {
  const store = useContext(StoreContext);
  const {vh, vw} = getUnits(useWindowDimensions());
  const colors = getColors();
  const [departures, setDepartures] = useState(null);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.background,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    message: {
      flex: -1,
      backgroundColor: colors.background,
      color: colors.hint,
      padding: 20 * vw,
      fontSize: 30,
    },
    cards: {
      flex: -1,
      width: '100%',
      alignItems: 'center',
    },
    buttons: {
      flexShrink: 0,
      flexGrow: 0,
      flexDirection: 'row',
      width: '100%',
      padding: 3 * vw,
      justifyContent: 'space-evenly',
    }
  });

  const onPress = (trip) => {
    return () => {
      navigation.navigate('Stops', {trip});
    }
  }

  const onSearch = () => {
    navigation.navigate('Search');
  }

  const onRefresh = () => {
    if(store.forceStation) {
      updateDepartures();
    } else {
      store.actions.updateBoard();
    }
  }

  const processDepartures = (deps) => {
    const filterSeconds = -60;
    const getSeconds = (dep) => ((new Date(dep.when)).getTime() - store.tick.now) / 1000;
    const uniquie = [];
    return deps
      .map(dep => ({ ...dep, seconds: getSeconds(dep) }))
      .filter(dep => dep.seconds > filterSeconds)
      .map((dep) => {
        const hash = `${dep.line.name}-${dep.direction}`;
        if (!uniquie.includes(hash)) {
          uniquie.push(hash)
          return {...dep, first: true};
        }
        return {...dep, first: false};
      })
  }

  const updateDepartures = async (showMessages = true) => {
    console.log('updateDepartures');
    if(showMessages){
      store.actions.setAppStatus({ status: 'loading', message: 'Finding Departures...'});
      setDepartures(null);
    }

    let data = null;

    try {
      data = await store.vbb.departures(store.station.id, {duration: 180, results: 75, linesOfStops: false, remarks: false})
    } catch(e) {
      if(showMessages){
        store.actions.setAppStatus({
          status: 'error',
          message: 'Error getting departures'
        });
       }
      console.log('Departures Error:', e);
    }

    store.actions.setAppStatus({ status: 'done', message: ''});
    // getLastUpdate();
    setDepartures(processDepartures(data?.departures ?? []));
  }

  const getLastUpdate = () => {
    departures?.forEach((dep, index) => {
      if (dep.first) {
        console.log('get trip', dep.tripId, dep.line.name);
        store.vbb.trip(dep.tripId, dep.line.name, { stopovers: false, remarks: false }).then((trip) => {
          console.log('got trip.realtimeDataUpdatedAt', trip.realtimeDataUpdatedAt);
          if (trip.realtimeDataUpdatedAt) {
            const seconds = Math.round(((new Date).getTime() - trip.realtimeDataUpdatedAt*1000) / 1000);
            console.log('seconds', seconds);

            const newDepartures = [...departures];
            newDepartures[index].realtimeDataUpdatedAt = trip.realtimeDataUpdatedAt;
            setDepartures(newDepartures);
          }
        });
      }
    });
  }

  useEffect(() => {
    if (store.station){
      updateDepartures();
    }
  }, [store.station]);

  useEffect(() => {
    if (departures) {
      if (store.tick.minuteChange) {
        setDepartures(processDepartures(departures));
      } else {
        updateDepartures(false);
      }
    }
  }, [store.tick]);

  const cards = departures?.map((departure, index) => {
    return <DepartureCard onPress={onPress(departure)} locale={store.locale} departure={departure} key={index}/>
  });

  return (
    <View style={styles.container}>
      {store.appStatus.status == 'start' &&
        <Text style={styles.message}>{store.appStatus.message ?? ''}</Text>
      }
      {store.appStatus.status == 'loading' &&
        <Text style={styles.message}>{store.appStatus.message ?? 'Loading...'}</Text>
      }
      {store.appStatus.status == 'error' &&
        <Text style={styles.message}>{store.appStatus.message ?? 'Error'}</Text>
      }
      {store.appStatus.status == 'done' &&
        <View style={styles.cards}>
          <ScrollView
            horizontal={false}
            snapToInterval={6 * vw}
            refreshControl={
              Platform.OS === 'web' ? (
                <RefreshControlWeb
                refreshing={store.appStatus.status == 'loading'}
                onRefresh={onRefresh}
              />
              ):(
              <RefreshControl
                refreshing={store.appStatus.status == 'loading'}
                onRefresh={onRefresh}
              />)
            }
            >{cards?.length ? cards : [ <Text style={styles.message} key="0">No trips</Text> ]}</ScrollView>
        </View>
      }
      <View style={styles.buttons}>
        <Button title="Search" onPress={onSearch}/>
        <Button title="Refresh" onPress={onRefresh}/>
      </View>
      <StatusBar  style="auto"/>
    </View>
  );
}