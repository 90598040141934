export function ComposeStyle (styles) {
  return styles
    .filter(style => !!style)
    .reduce((prev, current) => {
      const result = { ...prev };
      for (const [selector, rules] of Object.entries(current)) {
        result[selector] = result[selector] || {}
        for (const [rule, value] of Object.entries(rules)) {
          if(Array.isArray(value) && Array.isArray(result[selector][rule])) {
            result[selector][rule] = result[selector][rule].concat(value)
          } else {
            result[selector][rule] = value;
          }
        }
      }
      return result;
    }, {})
}
