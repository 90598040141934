import { StatusBar } from 'expo-status-bar';
import React, { useState, useContext, useEffect } from 'react';
import { StyleSheet, View, useWindowDimensions, Platform, ScrollView, Text} from 'react-native';
import { SearchBar } from 'react-native-elements';

import StoreContext from '../utils/store-context';
import Button from '../components/button';
import getColors from '../styles/colors';
import getUnits from '../utils/screen-units';
import StopCard from './stop-card';
import useIsMounted from '../utils/use-is-mounted';

let timer = 0;

export default function Search({navigation}) {
  const store = useContext(StoreContext);
  const {vh, vw} = getUnits(useWindowDimensions());
  const colors = getColors();

  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [message, setMessage] = useState('Start typing to see results');
  const isMounted = useIsMounted()

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.background,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    search: {
      flexGrow: 0,
      flexShrink: 0,
      width: '100%',
      borderBottomWidth: 0,
      borderTopWidth: 0,
      backgroundColor: colors.background,
    },
    searchInputContainer: {
      backgroundColor: colors.cardBackground,
    },
    searchInput : {
      color: colors.text,
      ...Platform.select({
        ios: {},
        android: {},
        default: {
          outlineStyle: 'none',
        }
      })
    },
    message: {
      flex: -1,
      backgroundColor: colors.background,
      color: colors.hint,
      padding: 20 * vw,
      fontSize: 30,
    },
    results: {
      flexGrow: 1,
      flexShrink: 1,
      width: '100%',
      alignItems: 'center',
    },
    buttons: {
      flexGrow: 0,
      flexShrink: 0,
      flexDirection: 'row',
      width: '100%',
      padding: 3 * vw,
      justifyContent: 'space-evenly',
    },
  });

  const updateSearch = (search) => {
    setSearch(search);
  };

  useEffect(() => {
    if(search.trim() !== '')
      doSearch(search.trim());
    else
      if (store.location)
        doDefaultSearch();
  }, [search]);

  const onBack = async () => {
    navigation.goBack();
  }

  const onClear = async () => {
    store.actions.setForceStation(null);
    navigation.goBack();
  }

  const onResultClick = (result) => {
    return () => {
      store.actions.setForceStation(result);
      navigation.goBack();
    }
  }

  const doDefaultSearch = async () => {
      setMessage('Loading...');
      // console.log('doDefaultSearch Fetch mounted: ', isMounted());
      const data = await store.vbb.nearby({
        type: 'location',
        latitude: store.location.coords.latitude,
        longitude: store.location.coords.longitude,
      }, {distance: 1000, poi: false, results: 10});
      // console.log('doDefaultSearch Done mounted: ', isMounted());
      if (isMounted()) {
        setResults(data);
        setMessage(null);
      }
  }

  const doSearch = (query) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      setMessage('Loading...');
      const data = await store.vbb.locations(query, {addresses: false, poi: false, results: 10});
      if (isMounted()) {
        setResults(data);
        setMessage(null);
      }
    }, 400)
  }

  const resultsMap = results.map((result, index) => (
    <StopCard stop={result} onPress={onResultClick(result)} key={index} />
  ));

  return (
    <View style={styles.container}>
      <SearchBar
        containerStyle={styles.search}
        inputContainerStyle={styles.searchInputContainer}
        inputStyle={styles.searchInput}
        placeholderTextColor={colors.hint}
        placeholder="Type Here..."
        onChangeText={updateSearch}
        value={search}
      />
      <View style={styles.results}>
        <ScrollView
          horizontal={false}
          snapToInterval={6 * vw}
          keyboardShouldPersistTaps='handled'
          >{resultsMap?.length ? resultsMap : [
            <Text style={styles.message} key="0">{message !== null ? message : 'No results'}</Text>
          ]}
        </ScrollView>
      </View>
      <View style={styles.buttons}>
        <Button title="Back" onPress={onBack} />
        <Button title="Show Closest Stop" onPress={onClear}/>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}