import React from 'react';
import { StyleSheet, Text } from 'react-native';
import Logos from './lines';

const styles = {
  RE1 : ['RE 1','white','#EE1C23','#EE1C23'],
  RE2 : ['RE 2','black','#FFD403','#FFD403'],
  RE3 : ['RE 3','white','#F57921','#F57921'],
  RE4 : ['RE 4','white','#952D4F','#952D4F'],
  RE5 : ['RE 5','white','#0072BC','#0072BC'],
  RE6 : ['RE 6','white','#DB6EAB','#DB6EAB'],
  RE7 : ['RE 7','white','#00854A','#00854A'],
  RE10: ['RE 10','white','#A7653F','#A7653F'],
  RE15: ['RE 15','black','#FFD403','#FFD403'],
  RE18: ['RE 18','white','#F57921','#F57921'],
  RE66: ['RE 66','white','#00854A','#00854A'],
  RB10: ['RB 10','white','#60BB46','#60BB46'],
  RB11: ['RB 11','white','#059EDB','#059EDB'],
  RB12: ['RB 12','white','#A3238E','#A3238E'],
  RB13: ['RB 13','white','#F68B1F','#F68B1F'],
  RB14: ['RB 14','white','#A3238E','#A3238E'],
  RB19: ['RB 19','white','#952D4F'],
  RB20: ['RB 20','white','#00854A','#00854A'],
  RB21: ['RB 21','white','#5E6DB3','#5E6DB3'],
  RB22: ['RB 22','white','#0087CB','#0087CB'],
  RB23: ['RB 23','white','#A3238E','#A3238E'],
  RB24: ['RB 24','white','#5E6DB3','#5E6DB3'],
  RB25: ['RB 25','white','#0087CB','#0087CB'],
  RB26: ['RB 26','white','#00A896','#00A896'],
  RB27: ['RB 27','white','#EE1C23','#EE1C23'],
  RB31: ['RB 31','white','#60BB46','#60BB46'],
  RB33: ['RB 33','white','#EE1C23','#EE1C23'],
  RB35: ['RB 35','white','#5E6DB3','#5E6DB3'],
  RB36: ['RB 36','white','#A7653F','#A7653F'],
  RB43: ['RB 43','white','#5E6DB3','#5E6DB3'],
  RB45: ['RB 45','black','#FFD403','#FFD403'],
  RB46: ['RB 46','white','#DB6EAB','#DB6EAB'],
  RB49: ['RB 49','white','#952D4F'],
  RB51: ['RB 51','white','#DB6EAB','#DB6EAB'],
  RB54: ['RB 54','white','#5E6DB3','#5E6DB3'],
  RB55: ['RB 55','white','#F57921','#F57921'],
  RB60: ['RB 60','white','#60BB46','#60BB46'],
  RB61: ['RB 61','white','#952D4F'],
  RB62: ['RB 62','white','#DB6EAB','#DB6EAB'],
  RB63: ['RB 63','black','#FFD403','#FFD403'],
  RB65: ['RB 65','white','#0072BC','#0072BC'],
  RB66: ['RB 66','white','#00854A','#00854A'],
  RB70: ['RB 70','white','#A7653F','#A7653F'],
  RB73: ['RB 73','white','#00A896','#00A896'],
  RB74: ['RB 74','white','#0072BC','#0072BC'],
  RB91: ['RB 91','white','#A7653F','#A7653F'],
  RB93: ['RB 93','white','#A7653F','#A7653F'],
  FEX : ['FEX','white','#952D4F'],
  HBX : ['HBX','white','#EE1C23'],
  OE65: ['OE 65','white','#0072BC','#0072BC'],
  ICE : ['','red','white'],
  IC : ['','#BB007C','white'],
  EC : ['','red','white'],
  Default: ['','white','#000000','#000000'],

}

export function HasLogo (name) {
  // console.log(Logos);
  if (Logos[name]) return 'logo-svg';
  if (styles[name]) return 'logo-style';
  if (name.startsWith('IC')) return 'logo-style';
  if (name.startsWith('EC')) return 'logo-style';
  return false;
}

export default function LineLogo(props) {
  const name = props.name;


  if (HasLogo(name) == 'logo-svg') {
    const LogoTag = Logos[name];
    if (typeof Logos[name] === 'string'){
      return(
        <img alt={name} height={props.height} src={Logos[name]}/>
      )
    } else {
      return(
        <LogoTag height={props.height} width={props.width} />
      )
    }
  } else {
    let lineStyle = styles.Default;


    if(styles[name]) {
      lineStyle = styles[name]
    } else if (name.startsWith('ICE')) {
      lineStyle = styles.ICE;
    } else if (name.startsWith('IC')) {
      lineStyle = styles.IC;
    } else if (name.startsWith('EC')) {
      lineStyle = styles.EC;
    }

    if(lineStyle[0] === '') lineStyle[0] = name;

    const style = StyleSheet.create({
      text: {
        color: lineStyle[1],
        backgroundColor: lineStyle[2],
        ...props.style,
      }
    });
    return(
      <Text numberOfLines={1} style={style.text}>{lineStyle[0]}</Text>
    )
  }
}