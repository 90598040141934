import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Text, View, useWindowDimensions, Pressable } from 'react-native';
import getColors from '../styles/colors';
import getUnits from '../utils/screen-units';
import { ComposeStyle } from '../utils/styles';
import LineLogo, {HasLogo} from './line-logo';
import TimeDisplay from './time-display';

export default function DepartureCard(props) {
  const departure = props.departure;
  const {vh, vw} = getUnits(useWindowDimensions());
  const colors = getColors();
  const dateDep = new Date(departure.when);

  const baseStyles = {
    card: {
      backgroundColor: colors.cardBackground,
      borderRadius: 1 * vw,
      width: 100 * vw,
      height: 12 * vw,
      // marginLeft: 2 * vw,
      // marginRight: 2 * vw,
      paddingLeft: 1 * vw,
      paddingRight: 1 * vw,
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 0.5 * vw,
      flexDirection: 'row',
    },
    logoContainer: {
      minWidth: 13 * vw,
      justifyContent: 'center',
    },
    nameContainer: {
      minWidth: 13 * vw,
      justifyContent: 'center',
    },
    logoName: {
      fontSize: 5 * vw,
      fontWeight: 'bold',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingVertical: 0.5 * vw,
      paddingLeft: 2 * vw,
      paddingRight: 2 * vw,
      borderRadius: 3 * vw,
    },
    name: {
      color: colors.text,
      fontSize: 6 * vw,
      fontWeight: 'bold',
      paddingVertical: 0 * vw,
      paddingLeft: 1 * vw,
      paddingRight: 1 * vw,
      // marginRight: 'auto',
      // marginLeft: 'auto',
    },
    direction: {
      color: colors.altText,
      fontSize: 5 * vw,
      flex: 1,
      marginLeft: 1 * vw,
      marginRight: 1 * vw,
    },
    time: {
      color: colors.text,
      fontSize: 6 * vw,
    },
  };

  const trainStyles = {
  };

  const firstStyles = {
    card: {
      backgroundColor: colors.cardBackgroundHint,
    },
  };

  const hasLogo = HasLogo(departure.line.name)

  const isRealTrain = (dep) => (
    dep.line.mode == 'train'
    && dep.line.product !== 'suburban'
    && dep.line.product !== 'tram'
    && dep.line.product !== 'subway'
  );

  const makeStyles = () => ComposeStyle([
      baseStyles,
      isRealTrain(departure) && trainStyles,
      !isRealTrain(departure) && departure.first && firstStyles,
  ])

  const [reactiveStyles, setReactiveStyles] = useState(makeStyles());

  useLayoutEffect(() => {
    setReactiveStyles(makeStyles());
  }, [departure]);

  return (
    <Pressable onPress={props.onPress} style={reactiveStyles.card}>
      {hasLogo &&
        <View style={reactiveStyles.logoContainer}>
          <LineLogo name={departure.line.name} style={reactiveStyles.logoName} height={6 * vw} width={'100%'} />
        </View>
      }{!hasLogo &&
        <View style={reactiveStyles.nameContainer}>
          <Text numberOfLines={1} style={reactiveStyles.name}>{departure.line.name}</Text>
        </View>
      }
      <Text numberOfLines={1} style={reactiveStyles.direction}>{departure.direction}</Text>
      <TimeDisplay style={reactiveStyles.time} time={dateDep} seconds={departure.seconds}/>
    </Pressable>
  );
}