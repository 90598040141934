import { Appearance } from "react-native";

const getColors = () => {
  const colorScheme = Appearance.getColorScheme();

  if (colorScheme === 'dark') {
    return {
      background: '#000',
      text: '#fff',
      hint: '#777',
      accent: '#FF6800',
      cardBackground: '#232020',
      cardBackgroundHint: '#4d4646',
      altText: '#a99',
      button: '#f90',
      buttonText: '#000',
      altButton: '#FFF',
      altButtonText: '#000',
    }
  } else {
    return {
      background: '#fff',
      text: '#000',
      hint: '#777',
      accent: '#FF6800',
      cardBackground: '#ddd',
      cardBackgroundHint: '#e3cec3',
      altText: '#555',
      button: '#f90',
      buttonText: '#FFF',
      altButton: '#FFF',
      altButtonText: '#000',
    }
  }
}

export default getColors