import React from 'react';
import { Text } from 'react-native';
import { format } from 'date-fns';

export default function TimeDisplay(props) {
  try {
  return (
    <Text style={props.style}>
      {(props.seconds<-60) &&
         'Left'
      }
      {(props.seconds>=-60) && (props.seconds<0) &&
          'Now'
      }
      {(props.seconds>=0) && (props.seconds<3600) &&
          `${Math.ceil(props.seconds/60)} m`
      }
      {(props.seconds>=3600) &&
          `${format(new Date(props.time), 'HH:mm')}`
      }
    </Text>
  );
  } catch(e) {
    console.log ('Error ', e, props.time)
    return null;
  }
}