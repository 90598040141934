import 'react-native-url-polyfill/auto';

import React, { useState, useEffect, useRef } from 'react';
import { useColorScheme} from 'react-native';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Vbb from 'hafas-rest-api-client';
import * as Location from 'expo-location';

import Board from './components/board';
import Search from './components/search';
import Stops from './components/stops';
import { StoreProvider } from './utils/store-context';

const Stack = createNativeStackNavigator();

const apiEndpoint = 'https://v6-vbb.nicu.ro';

const vbb = Vbb(apiEndpoint, {
  userAgent: 'berlin-bus-display-dev',
})

const defaultStation = {
  id: '900000014104',
  name: 'Manteuffelstr./Köpenicker Str. (Location Error)'
};

const tickInteval = 60 * 1000;

export default function App() {
  const [locale, setLocale] = useState('en');
  const tickRef = useRef({now: Date.now(), minuteChange: true});
  const [tick, setTick] = useState({now: Date.now(), minuteChange: true});
  const [forceStation, setForceStation] = useState(null);
  const [station, setStation] = useState(null);
  const [location, setLocation] = useState(null);
  const [appStatus, setAppStatus] = useState({status: 'start', mesaage: 'Loading...'});

  const updateBoard = () => {
    console.log('updateBoard');

    if (forceStation !== null){
      setStation(forceStation);
    } else {
      updateLocation();
    }
  }

  const updateLocation = async (retries = 5) => {
    console.log('updateLocation');
    setAppStatus({ status: 'loading', message: 'Updating Location...'});

    let { status } = await Location.requestForegroundPermissionsAsync();

    if (status !== 'granted') {
      setLocation(null);
      setAppStatus({
        status: 'error',
        message: 'Please allow location permision to see nearby stop'
      });
      console.log('Location Foreground Permissions:', status);
      return;
    }

    let deviceLocation = null
    try {
      console.log('getCurrentPositionAsync');
      const timeout = new Promise((resolve, reject) => setTimeout(() => reject('timeout'), 1000));
      deviceLocation = await Promise.race([ Location.getCurrentPositionAsync({}), timeout ]);
    } catch (e) {
      console.log('getCurrentPositionAsync - error:', e,' retries:', retries);
      if (retries > 0) {
        updateLocation(retries - 1);
        return;
      }
      setLocation(null);
      setAppStatus({
        status: 'error',
        message: 'Error getting location'
      });
      console.log('Location Error:', e);
      return;
    }
    console.log('getCurrentPositionAsync - done');
    setLocation(deviceLocation);
  }

  const updateStation = async () => {
    console.log('updateStation');

    if (forceStation !== null){
      console.log('updateStation aborted');
      return;
    }

    setAppStatus({ status: 'loading', message: 'Finding Stop...'});

    try {
      let nearby = await vbb.nearby({latitude: location.coords.latitude, longitude: location.coords.longitude})
      if (nearby[0]) {
        setStation(nearby[0]);
      } else {
        setAppStatus({
          status: 'error',
          message: 'Error finding nearby stop. This application only works for Berlin, Germany'
        });
      }

    } catch(e) {
      setStation(null);
      setAppStatus({
        status: 'error',
        message: 'Error finding nearby stop'
      });
      console.log('Station Error:', e);
    }
  }

  useEffect(() => {
    setStation(null);
    updateBoard()
  }, [forceStation]);

  useEffect(() => {
    if (location){
      updateStation()
    }
  }, [location]);

  useEffect(() => {
    tickRef.current = tick;
  }, [tick]);

  useEffect(() => {
    let timeoutId1 = 0;
    let timeoutId2 = 0;

    const updateTick = () => {
      const randomTime = Math.random()*40000+10000;
      const now = Date.now()
      const milsToNextTik = tickInteval - (now % tickInteval);

      clearTimeout(timeoutId2);
      timeoutId1 = setTimeout(updateTick, milsToNextTik + 500);
      timeoutId2 = setTimeout(() => {
        const now = Date.now()
        setTick({minuteChange: false, now});
        // console.log(`tick random - sec: ${Math.floor(now % 60000)}`);
      }, randomTime);

      // console.log(`tick minute - sec: ${Math.floor(now % 60000)}, milsToNextTik: ${milsToNextTik}, randomTime: ${randomTime}, minute tick`);
      setTick({minuteChange: true, now});
    }
    updateTick();

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    }
  }, []);

  const scheme = useColorScheme();

  return (
    <StoreProvider value={{
      locale,
      tick,
      appStatus,
      forceStation,
      location,
      station,
      actions: {updateBoard, setForceStation, setAppStatus},
      vbb
    }}>
    <NavigationContainer theme={scheme === 'dark' ? DarkTheme : DefaultTheme}>
      <Stack.Navigator>
        <Stack.Screen name="Departures" component={Board} options={{title: `${station?.name ?? 'Departures'}${forceStation && ' 🔒' || ''}`, headerShown: true, animationEnabled: false}}/>
        <Stack.Screen name="Search" component={Search} options={{headerShown: true, presentation: 'transparentModal'}}/>
        <Stack.Screen name="Stops" component={Stops} options={{headerShown: true, presentation: 'transparentModal'}}/>
      </Stack.Navigator>
    </NavigationContainer>
  </StoreProvider>
  );
}


